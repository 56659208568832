<template>
  <div class="cate-container rtl">
     <van-nav-bar
      :title="cates.detail.name"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>

    <CardCategory :cates="cates.sub" />
  </div>
</template>

<script>
import CardCategory from '@/components/CardCategory'
import { getSubCategorty } from '@/api/home'
export default {
  data () {
    return {
      cates: []
    }
  },
  components: {
    CardCategory
  },
  mounted () {
    this.queryCateData(this.$route.params.id)
  },
  methods: {
    async queryCateData (id) {
      const res = await getSubCategorty(id)
      if (res.errorCode === 0) this.cates = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.cate-container {
  height: 100vh;
  background-color: #fff;
  padding-top: 82px;
}
</style>
