<template>
  <ul class="cates">
    <li
      v-for="(item, index) in cates"
      :key="index"
      @click="queryCateData(item.id)"
    >
      <van-image lazy-load :src="item.ad_cover" />
      <p class="cate_name">{{ item.name }}</p>
    </li>
  </ul>
</template>

<script>
import { getSubCategorty } from '@/api/home'
export default {
  props: {
    cates: {
      type: Array
    }
  },
  methods: {
    async queryCateData (id) {
      const res = await getSubCategorty(id)
      if (res.errorCode === 0) {
        const sub = res.data.sub
        if (sub && sub.length) return this.$router.push(`category/${id}`)
        this.$router.push({
          path: '/grid',
          query: {
            id
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cates {
  display: flex;
  direction: rtl;
  background-color: #fff;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0 0 20px;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    text-align: center;
    margin: 20px 0 0;

    /deep/ .van-image {
      width: 94%;
      height: 200px;
      border-radius: 6px !important;
      object-fit: cover;
      overflow: hidden;
    }

    .cate_name {
      display: block;
      font-size: 26px;
      text-align: center;
      margin-top: 14px;
    }
  }
}
</style>
